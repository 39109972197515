import Head from "next/head";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import { userDataState, userState } from "@/lib/store";
import { useEffect } from "react";
import CognitoLogin from "@/components/cognito-login/CognitoLogin";
import Login from "@/components/login/Login";

const LoginPage: NextPage = (props) => {
    const router = useRouter();
    const user = useRecoilValue(userState);

    const { query } = useRouter();
    const userData = useRecoilValue(userDataState);

    useEffect(() => {
        if (query.hasOwnProperty("promo") && userData?.first_name) {
            query.promo ? sessionStorage.setItem("promoCode", query.promo as string) : sessionStorage.setItem("promoCode", "true");
        }
    }, [query.message, query.promo, userData?.first_name]);

    useEffect(() => {
        user && user["access_level"] && user["access_level"] !== -1 && router.push("/");
    }, [user]);

    return (
        <>
            <Head>
                <title>Login - ORTEX</title>
                <link rel="canonical" href="https://app.ortex.com/login" />
                <meta name="twitter:title" content="Login - ORTEX" />
                <meta name="twitter:url" content="https://app.ortex.com/login" />
                <meta property="og:title" content="Login - ORTEX" />
                <meta property="og:url" content="https://app.ortex.com/login" />
            </Head>
            <CognitoLogin />
        </>
    );
};

export default LoginPage;
